<!-- under the 3 sets of wheels, have the options the add more -->
<!-- answer explaination for 23-28mm tires -->

<template>
  <div class="header" style="padding: 8px">
      <v-row :class="{'justify-space-between': isMobile}" no-gutters style="align-items: center;">
        <v-col cols="auto" style="display:flex; align-items: center;">
          <!-- PANDA PODIUM ICON -->
          <a v-if="!isMobile" href="https://www.pandapodium.cc/" style="padding: 0;">
            <v-img src="/images/pandaPodiumWhiteLogo.png" class="headerImg" max-height="50" style="min-width: 200px; margin-top: -5px;" contain></v-img>
          </a>

          <!-- HOME PAGE ICON -->
            <router-link v-if="returnToMainPageIcon" to="/">
                <v-icon :size="isMobile ? 'x-large' : 'large'" :style="{ color: 'white',marginLeft: '-15px'}" class="homeIcon">mdi-home</v-icon>
            </router-link>

          <!-- PANDA PODIUM ICON for mobile -->
          <a v-if="isMobile" href="https://www.pandapodium.cc/" style="padding: 0;">
            <v-img src="/images/pandaPodiumWhiteLogo.png" class="headerImg" max-height="50" style="min-width: 200px; margin-top: -5px;" contain></v-img>
          </a>
        </v-col>

        <v-col v-show="!isMobile" class="text-center">
        </v-col>

        <v-col cols="auto" class="text-right" style = "display:flex; align-items: center;">
          <!-- PROP CHECKBOXES -->
           <div style = "display: flex; justify-content: flex-start; " >
            <template v-for="(checkBox, index) in checkBoxs" :key="index">
              <v-checkbox
                v-show = "!(checkBox.hideOnMobile && isMobile)"
                v-model= "localCheckBoxs[index].value"
                color="white"
                base-color="white"
              >
                <template v-slot:label>
                  <span class="checkboxLabel">{{ checkBox.label }}</span>
                </template>
              </v-checkbox>
            </template>
          </div>


          <!-- DAY/NIGHT MODE -->
          <v-btn icon @click="toggleTheme" elevation="0" color="rgb(var(--v-theme-headerBackground))">
            <v-icon color="white">{{ themeIcon }}</v-icon>
          </v-btn>

          <!-- PROP ICONS -->
          <template v-for="(iconObj, index) in icons" :key="index">
            <!-- IF TOOLTIP -->
            <v-tooltip v-if="iconObj.text" >
              <template v-slot:activator="{ props }">
                <v-btn icon v-bind="props" @click="emitIconClick(iconObj.event)" elevation="0" color="rgb(var(--v-theme-headerBackground))">
                  <v-icon color="white">{{ iconObj.icon }}</v-icon>
                </v-btn>
              </template>
              <span>{{ iconObj.text }}</span>
            </v-tooltip>

            <!-- NO TOOLTIP -->
            <v-btn v-else icon @click="emitIconClick(iconObj.event)" elevation="0" color="rgb(var(--v-theme-headerBackground))">
              <v-icon color="white">{{ iconObj.icon }}</v-icon>
            </v-btn>
          </template>
        </v-col>
      </v-row>
  </div>
</template>

<script>
/* eslint-disable */ 

import { ref, computed } from 'vue';
import { useTheme } from 'vuetify';

export default {
    name: 'BasicHeader',
    props:{
      returnToMainPageIcon:{
        type: Boolean,
        default: true
      },
      icons: {
        type: Array,
        default: () => []
      },
      checkBoxs:{
        type: Array,
        default: () => []
      }
    },
    setup(){
        const theme = useTheme();
        const themeName = ref(theme.global.name.value);

        const storedTheme = localStorage.getItem('theme');
        if (storedTheme) {
            themeName.value = storedTheme;
            theme.global.name.value = storedTheme;
        }

        const toggleTheme = () => {
            themeName.value = themeName.value === 'pandaPodiumLightTheme' ? 'pandaPodiumDarkTheme' : 'pandaPodiumLightTheme';
            theme.global.name.value = themeName.value;
            localStorage.setItem('theme', themeName.value);
        };

        const themeIcon = computed(() => themeName.value === 'pandaPodiumLightTheme' ? 'mdi-weather-night' : 'mdi-weather-sunny');


        return { toggleTheme, themeIcon};
    },
    data(){
      return {
          isMobile: window.innerWidth <= 768,
          localCheckBoxs: [...this.checkBoxs]
      };
    },
    watch:{
      checkBoxs: {
        handler(newVal) {
          // console.log("Here")
          this.localCheckBoxs = [...newVal];
        },
        immediate: true,
        deep: true
      },

      localCheckBoxs: {
        handler(newVal,oldVal) {
          // iterate over the new value and check if the value is different from the old value
          // if so emit the change
          if(newVal === undefined) return;
          if(oldVal === undefined) return;

          newVal.forEach((item)=>{
            this.$emit(item.event, item.value)
          })

        },
        immediate: true,
        deep: true
      }
    },
    methods: {
      emitIconClick(event) {
          this.$emit(event);
      },
    }
};
</script>

<style scoped>
.header {
    /* #373237 */
    background-color: rgb(var(--v-theme-headerBackground)) !important;
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
}

.checkboxLabel{
  color:white;
}


:deep(.v-input__details) {
    display: none !important;
}
/* mobile */
@media (max-width: 768px) {
  .checkboxLabel{
    font-size:12px;
  }

  .headerImg{
    min-width: 150px !important;
    width: 150px;
  }

  .homeIcon{
    margin-left:3px !important;
    margin-right:10px !important;
  }
}


</style>