<template>
  <div class="text-center fineTuneUI" style="margin-bottom:-2.5px">
    <div style="display: flex; align-items: center; justify-content: space-between">

      <div class="weFoundxWheelsText">
          We found <strong>{{ displayWheelsets.length }}</strong> wheels for you
      </div>

      <!-- DESKTOP FINE TUNE YOUR RESULTS -->
      <div v-if="!isMobile" style="display: flex; justify-content: center; margin-bottom: -40px;">
        <!-- CHECKBOX (SLIDER) -->
        <v-checkbox
          v-model="localSliderCheckbox"
          style="margin-bottom: 20px;"
          color="rgb(var(--v-theme-blackWhite))"
        >
          <template v-slot:label>
            <span id="checkboxLabel" style="opacity: unset; font-weight: 600;">FINE TUNE YOUR RESULTS</span>
            <v-icon style="color: rgb(var(--v-theme-blackWhite)); margin-left: 5px; ">mdi-filter-outline</v-icon>
          </template>
        </v-checkbox>
      </div>

      <!-- MOBILE FINE TUNE YOUR RESULTS -->
      <!-- use /images/wheelsetFinder/filter.png as icon -->
      <div v-else style="display: flex; justify-content: center;">
        <v-icon @click="toggleSliderCheckbox" style="color: rgb(var(--v-theme-blackWhite)); margin-left: -15px;">mdi-filter-outline</v-icon>
      </div>
    </div>

    <!-- SLIDERS -->
    <v-container v-show="localSliderCheckbox" style="border: 1px solid rgb(var(--v-theme-blackWhite),0.3); border-radius: 20px; padding: 10px; margin-bottom: 20px;">

        <v-row style = "padding: 6px 15px;">
          <!-- RIM DEPTH SLIDER -->
          <v-col cols="12" lg="4">
            <div class="text-caption" style="margin-bottom: 10px">
              Rim Depth Preference
            </div>
            <v-slider
              :disabled="!localSliderCheckbox"
              v-model="localRimDepthPref"
              :max="3"
              track-color="grey"
              color="#EA93E9"
              :ticks="rimDepthTickLabels"
              show-ticks="always"
              step="1"
              tick-size="3"
              :thumb-size="isMobile ? 5 : 20"
            ></v-slider>
          </v-col>
          
          <!-- STIFFNESS/LIGHTWEIGHT SLIDER -->
          <v-col cols="12" lg="4">
            <div class="text-caption" style="margin-bottom: 10px">
              Stiffness/Lightweight Preference
            </div>
            <v-slider
              :disabled="!localSliderCheckbox"
              v-model="localStiffnessLightweightPref"
              :max="3"
              track-color="grey"
              color="#EA93E9"
              :ticks="stiffnessTickLabels"
              show-ticks="always"
              step="1"
              tick-size="3"
              :thumb-size="isMobile ? 5 : 20"
            ></v-slider>
          </v-col>

          <!-- WEIGHT SLIDER -->
          <v-col cols="12" lg="4">
            <div class="text-caption" style="margin-bottom: 10px;">
              Rider&Bike Weight Combined
            </div>
            <v-slider
              :disabled="!localSliderCheckbox"
              label="kg"
              track-color="grey"
              color="#EA93E9"
              min="40"
              max="200"
              step="1"
              v-model="localWeightCombined"
              thumb-color="#EA93E9"
            >
                <template v-slot:append>
                <span>{{ localWeightCombined }} kg</span>

                </template>
            </v-slider>

          </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'FineTuneUI',
  props: {
    displayWheelsets: Array,
    isMobile: Boolean,
    sliderCheckbox: Boolean,
  },
  data() {
    return {
      localSliderCheckbox: this.sliderCheckbox,
      localWeightCombined: this.weightCombined,
      localStiffnessLightweightPref: this.stiffnessLightweightPref,
      localStiffnessBalancedPref: 0,
      localRimDepthPref: 0,
      stiffnessTickLabels: {
        0: 'N/A',
        1: 'light',
        2: 'balance',
        3: 'stiff',
      },
      rimDepthTickLabels: {
        0: 'N/A',
        1: 'shallow',
        2: 'medium',
        3: 'deep',
      },
    };
  },
  watch: {
    localSliderCheckbox(newVal) {
      this.$emit('update:sliderCheckbox', newVal);
    },
    localWeightCombined(newVal) {
      this.$emit('update:weightCombined', newVal);
    },
    localStiffnessLightweightPref(newVal) {
      this.$emit('update:stiffnessLightweightPref', newVal);
    },
    localRimDepthPref(newVal) {
      this.$emit('update:rimDepthPref', newVal);
    },
  },
  methods: {
    toggleSliderCheckbox() {
      this.localSliderCheckbox = !this.localSliderCheckbox;
    },
  },
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>