<template>
    <div class="option-container section-row" :style="containerStyle" style = "background-color: rgb(var(--v-theme-questionOptionsBackground));">
        <div style = "margin-top:10px; margin-bottom: 10px;">
            <!-- QUESTION-->
            <div class="section-title" style="margin-left:6px; margin-bottom: 7px; font-size: 14px; font-weight: 500; color:rgb(var(--v-theme-blackWhite));">
              {{ question.title }}

                <v-dialog v-if="question.mobileTooltipText" max-width="500">
                <template v-slot:activator="{ props: activatorProps }">
                  <v-icon v-show="isMobile" v-bind="activatorProps" style="margin-left: 10px; cursor: pointer;">
                    mdi-help-circle
                  </v-icon>
                </template>

                <template v-slot:default="{ isActive }">
                  <v-card>
                  <v-card-text>
                    <span v-html="question.mobileTooltipText"></span>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                    text="Close"
                    @click="isActive.value = false"
                    ></v-btn>
                  </v-card-actions>
                  </v-card>
                </template>
                </v-dialog>

            </div>
        
            <!-- ANSWERS -->
            <div class="options-container" style="column-gap:0px">
                <div
                    v-for="(option, index) in question.options"
                    :key="index"
                    class="option-col"
                    style="overflow: hidden; flex: 1; min-width: 0; margin: 5px;"
                >
                <!-- With tooltip -->
                <!-- :class="{ 'selected': selectedOptions[key] === option.title }" -->
                <v-tooltip v-if="option.tooltipText" location="top">
                    <template v-slot:activator="{ props }">
                        <v-btn
                        v-bind = "props"
                        @click="selectOption(option)"
                        class="option-btn"
                        :class="{ 'selected': selectedOptions[questionKey] === option.title }" 
                        style = "width: 100%; height:38px"
                        :disabled="disabled"
                        icon
                    >
                        <v-img
                            v-if="option.img && !isMobile"
                            :src="option.img"
                            alt=""
                            :style="imgStyle"
                        />
                        <span
                            style=" display: block; overflow-wrap: break-word; word-wrap: break-word; font-size:small"
                            class="optionText"
                        >
                            {{ option.shortTitle }}
                        </span>
                    </v-btn>
                    </template>
                    <span>{{ option.tooltipText }}</span>
                </v-tooltip>
        
                <!-- Without tooltip -->
                    <v-btn
                        v-else
                        @click="selectOption(option)"
                        class="option-btn"
                        style = "width: 100%; height:38px"
                        :disabled="disabled"
                        :class="{ 'selected': selectedOptions[questionKey] === option.title }" 
                        icon
                    >
                        <v-img
                            v-if="option.img && !isMobile"
                            :src="option.img"
                            alt=""
                            :style="imgStyle"
                        />
                        <span
                            style=" display: block; overflow-wrap: break-word; word-wrap: break-word; font-size: small;"
                            class="optionText"
                        >
                            {{ option.shortTitle }}
                        </span>
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>

  import { useTheme } from 'vuetify';
  import { computed } from 'vue';

  export default {
    name: 'QuestionOptions',
    props: {
      questionKey: String,
      question: Object,
      selectedOptions: Object,
      isMobile: Boolean,
      disabled: Boolean,
    },
    setup() {
    const theme = useTheme();
    const themeName = theme.global.name;

    const imgStyle = computed(() => ({
      height: '20px',
      width: '20px',
      marginRight: '7px',
      filter: themeName.value === 'pandaPodiumDarkTheme' ? 'invert(1)' : 'none'
    }));

    return {
      theme,
      imgStyle
    };
  },
    methods: {
      selectOption(option) {
        this.$emit('select-option', this.questionKey, option);
      },
    },
    computed: {
      containerStyle() {
        return {
          flexWrap: 'nowrap !important',
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid rgba(0, 0, 0, 0.2)',
          borderRadius: '5px',
          padding: '3px 9px',
        };
      },
    },
  };
  </script>
  
  <style scoped>
  .selected {
    background-color: rgb(var(--v-theme-buttonHover)) !important; 
    color: rgb(var(--v-theme-textPrimary)) !important;
  }

  .option-container {
    margin-bottom: 10px;
  }
  
  .section-title {
    display: flex;
    align-items: center;
  }
  
  .options-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .option-btn {
    white-space: normal;
    word-break: break-word;
    text-align: center;
    width: auto;
    background: rgb(var(--v-theme-button));
    color: rgb(var(--v-theme-textPrimary));
    border: none;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  
  .option-btn:hover {
    background: rgb(var(--v-theme-buttonHover));
  }
  
  .option-btn:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  @media (max-width: 768px) {
    .optionText {
      font-size: 8px !important;
    }
  }
  </style>