<template>
    <v-row v-if = "!simplified" style="margin-bottom: 1rem;">
      <v-col cols="5">
        <a :href="wheel.link" style="display:flex">
          <v-img
            :src="wheel.imgLink || 'https://via.placeholder.com/100'"
            lazy-src="https://via.placeholder.com/100"
            alt="Wheel image"
            cover
            aspect-ratio="1"
            rounded="xl"
          ></v-img>
        </a>
      </v-col>
      <v-col cols="7">
        <p style = "font-size: larger; margin-bottom: 5px;"><strong>{{ filterValue(wheel.name) }}</strong> </p>
        <p><strong>Price:</strong> {{ formatPrice(wheel.price) }}</p>
        <p><strong>Type:</strong> {{ filterValue(wheel.DiscOrRim) }}</p>
        <p><strong>Rim Depth(mm):</strong> {{ filterValue(wheel.frontRimDepth) }}</p>
        <p><strong>Weight(g):</strong> {{ filterValue(wheel.weight) }}</p>
        <p><strong>Inner Width(mm):</strong> {{ filterValue(wheel.frontRimInnerWidth) }}</p>
        <p v-if="wheel.riderWeightLimit"><strong>Rider Weight Limit(kg):</strong> {{ filterValue(wheel.riderWeightLimit) }}</p>
      </v-col>
    </v-row>

    <v-row v-else style="background-color:rgb(var(--v-theme-wheelResultBackground)); background-clip: content-box; margin-bottom:10px; margin-top:0px; padding-top:0px; border-radius: 20px; " >
      <v-col cols="2">
        <a :href="wheel.link" style="display:flex; margin-top:7px">
          <v-img
          
            :src="wheel.imgLink || '/images/common/PPColoredIcon.jpeg'"
            lazy-src="/images/common/PPColoredIcon.jpeg"
            alt="Wheel image"
            cover
            aspect-ratio="1"
            rounded = "lg"
          ></v-img>
        </a>
      </v-col>
      <v-col cols="10">
        <div style = "display:flex; justify-content: space-between; flex-direction:column;">
            <div>
                <p style = "font-size: larger; margin-bottom: 5px;"><strong>{{ filterValue(wheel.name) }}</strong> </p>
            </div>
            <div style = "display: flex; gap:10px">
                <p><strong>Price:</strong> {{ formatPrice(wheel.price) }}</p>
                <p><strong>Weight:</strong> {{ filterValue(wheel.weight) }}</p>
            </div>
        </div>
      </v-col>
    </v-row>
  </template>
  
  <script>
  export default {
    name: 'WheelItem',
    props: {
      wheel: {
        type: Object,
        required: true
      },
      simplified: {
        type: Boolean,
        default: false
      }
    },
    methods: {
        filterValue(value) {
      return value === -1 || value === '-1' ? 'N/A' : value;
    },
    formatPrice(price) {
      return price === -1 || price === '-1' ? 'N/A' : `$${price}`;
    },
    }
  };
  </script>
  
  <style scoped>
  /* Add any component-specific styles here */
  </style>