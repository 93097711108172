<!-- PROBLEM -->

<!-- IMPROVEMENTS -->


<!-- make description -->
<template>
  <v-card  elevation="0" class="mx-auto mainCont .scrollable-container" style="background:rgb(var(--v-theme-background));border-width: 0px; min-height: 100vh;border-radius:0px">

      <BasicHeader
          :icons="[{ icon: 'mdi-code-tags-check', event: 'dev-mode-on', text: 'dev mode' },]"
          @dev-mode-on="devModeOn"
      ></BasicHeader>

      <v-main class="main-content">
        <!-- DEV MODE INFO  -->
        <v-container v-if="devMode" class="text-center">
          <v-row>
            <v-col>
              <p style="color: red; font-weight: 600;">DEV MODE: </p>
              <!-- SCORE LIST -->
              <p>Score List: {{ scoreList }}</p>
            </v-col>
          </v-row>
        </v-container>

        <!-- TITLE -->
        <v-container class="text-center mt-5 title-container" :class = "{ 'title-container-mobile': isMobile }">
          <v-row>
            <v-col class="title" v-if="!isMobile" style = "display: flex;">
              <h1 style="font-weight:600">Panda Podium Wheelset Finder</h1>
            </v-col>
            <v-col class="title" v-else style = "display: flex; margin-top: -15px;">
              <v-container>
                <h1 style="font-weight:700; font-size:large">Panda Podium Wheelset Finder</h1>
              </v-container>
            </v-col>
          </v-row>
          <v-row v-show = "isMobile" style = "margin-top:-35px; margin-bottom: 6px;">
            <v-col>
              <!-- MOBILE TOOL DESCRIPTION DIALOG -->
              <v-dialog max-width="500">
                <template v-slot:activator="{ props: activatorProps }">
                  <v-chip  v-show = "isMobile" v-bind="activatorProps" style ="margin-bottom: -30px;">
                    What is this tool?
                  </v-chip>
                </template>

                <template v-slot:default="{ isActive }">
                  <v-card>
                    <v-card-text>
                      What type of bike do you have? Do you ride often? Or are you training for a race? These are important questions to ask when you're trying to make a wheelset match. This tool will help you understand which wheelsets is right for you.
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        text="Close"
                        @click="isActive.value = false"
                      ></v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>

            </v-col>
          </v-row>
          <v-row v-if = "!isMobile">
            <v-col>
              <p style="text-align: justify;">
                What type of bike do you have? Do you ride often? Or are you training for a race? These are important questions to ask when you're trying to make a wheelset match. This tool will help you understand which wheelsets is right for you.
              </p>
            </v-col>
          </v-row>

        </v-container>

        <!-- MAIN CONTENT -->
        <v-container  class="main-content-container" style = "display: flex; flex-wrap: wrap;">
          <!-- QUESTIONS -->
          <v-expand-transition>
            <v-container v-if="!showCollapsedQuestions ">
              <v-row>
                <v-col cols="12" lg="6" v-for="(question, key) in questions" :key="key" style="padding:0px;">
                  <div style="margin-right:10px">
                    <!-- DESKTOP QUESTIONS -->
                    <QuestionOptions
                      :questionKey="key"
                      :question="question"
                      :selectedOptions="selectedOptions"
                      :isMobile="isMobile"
                      :disabled="key !== 'wheelsetType' && gravelWheelSelected"
                      @select-option="selectOption"
                    />
                  </div>
                </v-col>
              </v-row>
              <!-- MOBILE CONFIRM BTN -->
              <div style="text-align: center;">
                <v-btn
                  v-if="isMobile && showConfirmedBtn"
                  :disabled="Object.keys(selectedOptions).length < questionsLength"
                  @click="confirmBtnClicked"
                  style="background-color: rgb(var(--v-theme-confirmButtonBackground)); margin-top: 20px; margin-bottom: 20px;"
                  v-tooltip.bottom="'Please answer all questions before continuing'"
                >
                  Confirm
                </v-btn>
              </div>
            </v-container>
          </v-expand-transition>


          <!-- COLLAPSED QUESTIONS -->
           <v-container v-if = "isMobile && showCollapsedQuestions">
            <v-card  style=" width: 100%; box-shadow: none; border: 1px solid #ccc; background-color: rgb(var(--v-theme-questionOptionsBackground)); border-radius: 14px !important;">
              <v-card-item>
                <!-- TITLE -->
                <div style = "display:flex; align-items: center; justify-content: space-between;">
                  <div>What are you looking for?</div>
                  <!-- EDIT BUTTONS -->
                  <span @click="showCollapsedQuestions = false; mobileQuestionsConfirmed = false; showConfirmedBtn = true" style="font-weight: bold; text-decoration: underline; cursor: pointer;">Edit</span>
                </div>


                <!-- SELECTED QUESTIONS -->
                <div style = "display: flex; margin-top: 10px; gap: 10px;flex-wrap: wrap;">
                  <div v-for="(shortValue, key) in shortSelectedOptions" :key="key" style="border: 1px solid rgba(0, 0, 0, 0.2);border-radius: 5px; padding: 3px 9px;">
                    {{ shortValue }}
                  </div>
                </div>
              </v-card-item>
            </v-card>
          </v-container>

          <!-- RESULTS -->
           <transition name = "fade">
            <v-container v-if="showResults" class="best-matches-section  section-row" style = "margin-top: -15px;">

                <!-- RESULTS -->
                <div v-if="showResults" style="padding-top:0px; padding-bottom: 0px;">

                  <!-- TITLE AND FINE-TUNE UI -->
                  <FineTuneUI
                    :displayWheelsets="displayWheelsets"
                    :isMobile="isMobile"
                    v-model:sliderCheckbox="sliderCheckbox"
                    v-model:weightCombined="weightCombined"
                    v-model:stiffnessLightweightPref="stiffnessLightweightPref"
                    v-model:rimDepthPref="rimDepthPref"
                  />

                  <!-- DESKTOP WHEELSET LIST -->
                  <div v-if = "!isMobile">
                    <v-row
                      v-for="(wheel, index) in paginatedWheelsets"
                      :key="index"
                      class="match-row align-center"
                      style="background-color:rgb(var(--v-theme-wheelResultBackground)); background-clip: content-box; margin-bottom:10px; margin-top:0px; padding-top:0px; border-radius: 20px;"
                    >
                      <v-col cols="2">
                        <a :href="wheel.link" style = "display:flex">
                          <v-img
                            :src="wheel.imgLink || '/images/common/PPColoredIcon.jpeg'"
                            lazy-src="/images/common/PPColoredIcon.jpeg"
                            alt="Wheel image"
                            cover
                            aspect-ratio="1"
                            rounded = "xl"
                          ></v-img>
                        </a>
                      </v-col>
                      <v-col cols="8">
                        <p style = "font-size: larger; margin-bottom: 5px;"><strong>{{ filterValue(wheel.name) }}</strong> </p>
                        <p style = "font-size: 13px;"><strong>Price:</strong> {{ formatPrice(wheel.price) }}</p>
                        <p style = "font-size: 13px;"><strong>Type:</strong> {{ filterValue(wheel.DiscOrRim) }}</p>
                        <p style = "font-size: 13px;"><strong>Front Rim Depth(mm):</strong> {{ filterValue(wheel.frontRimDepth) }}</p>
                        <p style = "font-size: 13px;"><strong>Weight(g):</strong> {{ filterValue(wheel.weight) }}</p>
                        <p style = "font-size: 13px;"><strong>Front Rim Inner Width(mm):</strong> {{ filterValue(wheel.frontRimInnerWidth) }}</p>
                        <!-- if weightCombined is set, show weight limit -->
                        <p v-if="weightCombined > 0"><strong>Rider Weight Limit(kg):</strong> {{ filterValue(wheel.riderWeightLimit) }}</p>
                      </v-col>
                      <v-col cols="2">
                        <v-btn :href="wheel.link"  style=" background-color: rgb(var(--v-theme-viewProductBackground));color:white; box-shadow: none; border: none; width: 80%; height: 38px; font-size: 11px; font-weight: 700; min-width: 120px;">
                          View Product
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>

                  <!-- MOBILE WHEELSET LIST -->
                  <div v-else>
                    <div
                      v-for="(wheel, index) in paginatedWheelsets"
                      :key="index"
                      class="match-row align-center"
                      style="background-color:rgb(var(--v-theme-wheelResultBackground)); background-clip: content-box; margin-bottom:10px; margin-top:0px; padding-top:0px; border-radius: 20px;"
                    >
                        <div style = "padding:14px">
                        <!-- WHEELSET TITLE -->
                        <div style = "font-size: larger; margin-bottom: 5px;"><strong>{{ filterValue(wheel.name) }}</strong> </div>

                        <!-- IMAGE AND SPECS -->
                        <v-row>
                          <v-col cols="5">
                            <a :href="wheel.link" style = "display:flex">
                              <v-img
                                :src="wheel.imgLink || 'https://via.placeholder.com/100'"
                                lazy-src="https://via.placeholder.com/100"
                                alt="Wheel image"
                                cover
                                aspect-ratio="1"
                                rounded = "xl"
                              ></v-img>
                            </a>
                          </v-col>
                          <v-col cols="7">
                            <p><strong>Price:</strong> {{ formatPrice(wheel.price) }}</p>
                            <p><strong>Type:</strong> {{ filterValue(wheel.DiscOrRim) }}</p>
                            <p><strong>Rim Depth(mm):</strong> {{ filterValue(wheel.frontRimDepth) }}</p>
                            <p><strong>Weight(g):</strong> {{ filterValue(wheel.weight) }}</p>
                            <p><strong>Innder Width(mm):</strong> {{ filterValue(wheel.frontRimInnerWidth) }}</p>
                            <p v-if="weightCombined > 0"><strong>Rider Weight Limit(kg):</strong> {{ filterValue(wheel.riderWeightLimit) }}</p>
                          </v-col>
                        </v-row>

                        <!-- VIEW PRODUCT LINK -->
                        <div style = "display:flex; align-items: center; justify-content: center; margin-top:10px;">
                          <v-btn :href="wheel.link" style=" background-color: rgb(var(--v-theme-viewProductBackground));color:white; box-shadow: none; border: none; width: 100%; height: 38px; font-size: 11px; font-weight: 700; min-width: 120px;">
                          View Product
                        </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- LOAD MORE -->
                  <v-row v-if="(displayCount < displayWheelsets.length) && (this.selectedOptions.wheelsetType !== 'Gravel Wheels') " class="text-center mb-5 mt-5" >
                    <v-col>
                      <v-btn @click="loadMore">Load More</v-btn>
                    </v-col>
                  </v-row>
                </div>

                <!-- NO RESULT -->
                <v-row v-if="displayWheelsets.length === 0" class="text-center">
                  <v-col>
                    <p>Sorry we couldn't find any wheels that meet your criteria</p>
                  </v-col>
                </v-row>

            </v-container>
          </transition>
        </v-container>
      </v-main>

  </v-card>
</template>

<script>
import BasicHeader from '@/components/common/BasicHeader.vue';
import wheelsetService from '@/services/wheelsetService.js';
import { useTheme } from 'vuetify'
import QuestionOptions from '@/components/wheelsetFinder/QuestionOptions.vue';
import FineTuneUI from '@/components/wheelsetFinder/FineTuneUI.vue';

export default {
  name: 'WheelsetFinder',
  components: {
    BasicHeader,
    QuestionOptions,
    FineTuneUI
  },
  setup(){
    const theme = useTheme();

    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      theme.global.name.value = storedTheme;
    }

    const toggleTheme = () => {
      theme.global.name.value = theme.global.name.value === 'pandaPodiumLightTheme' ? 'pandaPodiumDarkTheme' : 'pandaPodiumLightTheme';
      localStorage.setItem('theme', theme.global.name.value);
    };

    return { toggleTheme };

  },  
  data() {
    return {
      devMode: false,
      theme: 'light',
      weightCombined: -1,
      showCollapsedQuestions: false,
      questions: {
        wheelsetType: {
          title: "I'm looking for ...",
          options: [
            { title: "Road Wheels", shortTitle: "Road Wheels", attributes: null, nextQuestion: "iWantWheelsThat", img: "/images/wheelsetFinder/route.png" },
            { title: "Gravel Wheels", shortTitle:"Gravel wheels", attributes: null, nextQuestion: null, img: "/images/wheelsetFinder/mountain.png" } // No next question for Gravel Wheels
          ]
        },
        iWantWheelsThat: {
          title: "I want wheels that are ...",
          options: [
            { title: "Make my bike as fast as possible. Performance is everything", shortTitle: "Performance", attributes: { rimDepth: 1, weight: 1, stiffness: 1 }, nextQuestion: "imTryingToGoFastOn", tooltipText: "Stiff and Lightweight",img: "/images/wheelsetFinder/rocket.png" },
            { title: "Make my bike lighter / faster, but comfort is also important", shortTitle: "Comfortable", attributes: { rimDepth: -1, stiffness: -1 }, nextQuestion: "imLookingFor", tooltipText: "Less Stiff and Shallow", img: "/images/wheelsetFinder/cloud.png" },
            { title: "Make my bike look cool. Performance isn't important", shortTitle: "Just look cool", attributes: { lowPrice: 2, rimDepth:1 }, nextQuestion: "imLookingFor", tooltipText:"Budget", img: "/images/wheelsetFinder/sunglasses.png"}
          ],
          mobileTooltipText: "Performance: Stiff and Lightweight, <br/><br/> Comfort: Less Stiff and Shallow, <br/><br/> Just look cool: Budget"
        },
        imTryingToGoFastOn: {
          title: "I'm trying to go as fast as possible on ...",
          options: [
            { title: "On the flats", shortTitle: "Flats", attributes: { rimDepth: 2 }, nextQuestion: "roadSurfaces", img: "/images/wheelsetFinder/line.png" },
            { title: "On steep climbs", shortTitle: "Climbs", attributes: { rimDepth: -2 }, nextQuestion: "roadSurfaces", img: "/images/wheelsetFinder/mountain.png" },
            { title: "On rolling hills / mixed Terrain", shortTitle: "Mixed Terrain", attributes: { weight: 2 }, nextQuestion: "roadSurfaces", img: "/images/wheelsetFinder/route.png" }
          ]
        },
        roadSurfaces: {
          title: "The road surfaces where I ride are ...",
          options: [
            { title: "Are perfectly smooth, pristine tarmac", shortTitle: "Smooth ", attributes: { lowInnerWidth: 1 }, nextQuestion: null, tooltipText: "25mm Tires (18mm-23mm inner width)", img: "/images/wheelsetFinder/wind.png" },
            { title: "Are mediocre. Not bad, Not excellent", shortTitle: "Mediocre ", attributes: { mediumInnerWidth: 1 }, nextQuestion: null, tooltipText: "28mm Tires (23mm-25mm inner width)", img: "/images/wheelsetFinder/route.png" },
            { title: "Are terrible. Pot holes are everywhere and the surface is rough", shortTitle: "Rough", attributes: { highInnerWidth: 1 }, nextQuestion: null, tooltipText: "32mm Tires (25mm-28mm inner width)", img: "/images/wheelsetFinder/delete.png" }
          ],
          mobileTooltipText: "Are perfectly smooth, pristine tarmac: 25mm Tires (18mm-23mm inner width), <br/><br/> Are mediocre. Not bad, Not excellent: 28mm Tires (23mm-25mm inner width)<br/><br/> Are terrible. Pot holes are everywhere and the surface is rough: 32mm Tires (25mm-28mm inner width)"
        }
      },
      currentQuestionKey: 'wheelsetType',
      selectedOptions: {wheelsetType: "Road Wheels"},
      perfectMatch: [],
      bestMatches: [],
      jsonData: [],
      loading: false,
      showResults: false,
      isMobile: window.innerWidth <= 768,
      sliderCheckbox: false,
      wheelsets: [],
      displayWheelsets:[],
      addtionalLowPriceScore: 0,
      addtionalRimDepthScore: 0,
      addtionalWeightScore: 0,
      addtionalStiffnessScore: 0,
      displayCount: 3,
      gravelWheelSelected: false,
      showConfirmedBtn: false,
      mobileQuestionsConfirmed: false,
      stiffnessLightweightPref: 0,
      rimDepthPref:0,

    };
  },
  computed: {
    shortSelectedOptions() {
      const shortOptions = {};
      for (const key in this.selectedOptions) {
        const selectedValue = this.selectedOptions[key];
        const question = this.questions[key];
        if (question) {
          const option = question.options.find(opt => opt.title === selectedValue);
          if (option && option.shortTitle) {
            shortOptions[key] = option.shortTitle;
          } else {
            shortOptions[key] = selectedValue;
          }
        } else {
          shortOptions[key] = selectedValue;
        }
      }
      return shortOptions;
    },
    currentQuestion() {
      return this.questions[this.currentQuestionKey];
    },
    themeClass() {
      return this.theme === 'dark' ? 'theme-dark' : 'theme-light';
    },
    themeIcon() {
      return this.theme === 'dark' ? 'mdi-weather-sunny' : 'mdi-weather-night';
    },
    appBarColor() {
      return this.theme === 'dark' ? 'rgb(255 65 252 / 46%)' : '#fc32f9';
    },
    buttonStyle() {
      return this.theme === 'dark'
        ? { backgroundColor: '#333', color: '#fff', borderColor: '#fff' }
        : { backgroundColor: '#fff', color: '#000', borderColor: '#000' };
    },
    scoreList() {
      const scoreList = { lowPrice: 0, rimDepth: 0, weight: 0, stiffness: 0, lowInnerWidth: 0, mediumInnerWidth: 0, highInnerWidth: 0};
      for (const key in this.selectedOptions) {
        const selectedOption = this.questions[key].options.find(option => option.title === this.selectedOptions[key]);
        if (selectedOption && selectedOption.attributes) {
          for (const attr in selectedOption.attributes) {
            scoreList[attr] += selectedOption.attributes[attr];
          }
        }
      }
      scoreList.lowPrice += this.addtionalLowPriceScore;
      scoreList.rimDepth += this.addtionalRimDepthScore;
      scoreList.weight += this.addtionalWeightScore;
      scoreList.stiffness += this.addtionalStiffnessScore;

      return scoreList;
    },
    paginatedWheelsets() {
      if(this.selectedOptions.wheelsetType === "Gravel Wheels"){
        return this.displayWheelsets;
      }
      return this.displayWheelsets.slice(0, this.displayCount);
    },
    questionsLength(){
      return Object.keys(this.questions).length;
    }

  },
  methods: {
    loadMore() {
      this.displayCount += 3;
    },
    devModeOn(){
      this.devMode = !this.devMode;
    },
    selectOption(questionKey, option) {
      // if (this.selectedOptions[questionKey] !== option.title) {
      //   this.removeSubsequentQuestions(questionKey);
      // }

      this.selectedOptions[questionKey] = option.title;
      // pretty print selectedOptions object
      if (option.title === "Gravel Wheels") {
        this.gravelWheelSelected = true;
        this.removeAllButSpecificQuestion("wheelsetType");
        this.generateResults();
        this.showResults = true;
        if(this.isMobile){
            this.showCollapsedQuestions = true;
          }
      }
      else if (this.selectedOptions.wheelsetType === "Road Wheels" ) {
        this.gravelWheelSelected = false;
        // if selectedOptions has less than 4 options, then set showResults to false
        if(Object.keys(this.selectedOptions).length < this.questionsLength){
          this.showResults = false;
        }else if(this.isMobile && !this.mobileQuestionsConfirmed && this.showConfirmedBtn){
          this.showResults = false;
        }
        else{
          this.generateResults();
          this.showResults = true;

          if(this.isMobile){
            this.showCollapsedQuestions = true;
          }

        }
      }
      //   this.currentQuestionKey = null;


      // } else if (option.nextQuestion) {
      //   this.currentQuestionKey = option.nextQuestion;
      // } 
      
      // // RESULTS GENERATION
      // else {
      //   this.generateResults();
      //   // console.log(this.displayWheelsets);
      //   this.showResults = true;
      // }

    },
    removeSubsequentQuestions(questionKey) {
      this.showResults = false;
      const questionKeys = Object.keys(this.questions);
      const currentIndex = questionKeys.indexOf(questionKey);
      for (let i = currentIndex + 1; i < questionKeys.length; i++) {
        const key = questionKeys[i];
        if (this.selectedOptions[key]) {
          delete this.selectedOptions[key];
        }
      }

      // Scroll to the top of the page
      if(this.showResults && !this.isMobile) window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    removeAllButSpecificQuestion(specificQuestionKey) {
      const questionKeys = Object.keys(this.questions);
      for (const key of questionKeys) {
        if (key !== specificQuestionKey && this.selectedOptions[key]) {
          delete this.selectedOptions[key];
        }
      }
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    generateResults(){
      if(this.wheelsets.length === 0) return;
      
      let tempWheelsets = this.wheelsets;
      // if weightCombined is set, then first run filterRiderWeight

      if(this.weightCombined > 0){
        tempWheelsets = this.filterRiderWeight(tempWheelsets);
      }

      if(this.stiffnessLightweightPref >= 0){
          this.addtionalWeightScore = 0;
          this.addtionalStiffnessScore = 0;
          this.addtionalRimDepthScore = 0;

        if(this.stiffnessLightweightPref === 1){
          this.addtionalWeightScore += 2;
        }
        else if(this.stiffnessLightweightPref === 2){
          this.addtionalWeightScore += 1;
          this.addtionalStiffnessScore += 1;
          this.addtionalRimDepthScore += 1;
        }
        else if(this.stiffnessLightweightPref === 3){
          this.addtionalStiffnessScore += 2;
        }
      }

      if(this.rimDepthPref >= 0){
        this.addtionalRimDepthScore = 0;
        if(this.rimDepthPref === 1){
          this.addtionalRimDepthScore -= 2;
        }
        else if(this.rimDepthPref === 2){
          this.addtionalRimDepthScore += 0;
        }
        else if(this.rimDepthPref === 3){
          this.addtionalRimDepthScore += 2;
        }
      }

      // GRAVEL WHEELS
      if(this.selectedOptions.wheelsetType === "Gravel Wheels"){
        this.displayWheelsets = tempWheelsets.filter(wheelset => wheelset.intendedUse.toLowerCase().includes('gravel'));
      }
      // ROAD WHEELS
      else{
        tempWheelsets = this.filterInnerWidth(tempWheelsets);
        this.displayWheelsets = this.sortWheelsets(this.scoreList, tempWheelsets);

        // sort displayWheelsets by xfactorIndex, from high to low and print all displayWheelsets's xfactorIndex from high to low
        this.displayWheelsets = this.displayWheelsets.sort((a, b) => b.xfactorIndex - a.xfactorIndex);
        this.displayWheelsets.forEach( wheelset => {
          console.log(wheelset.name + " x-index: " + wheelset.xfactorIndex);
        });
      }

      // reset displayCount
      this.displayCount = 3;
    },
    filterRiderWeight(wheelsets){
      if(this.weightCombined <= 90){
        return wheelsets;
      }
      else{
        return wheelsets.filter(wheelset => wheelset.riderWeightLimit > this.weightCombined);
      }

    },
    // INNER WIDTH FILTER
    filterInnerWidth(wheelsets){
      const tireWidth = this.selectedOptions.roadSurfaces === "Are perfectly smooth, pristine tarmac" ? 25 : 
                        this.selectedOptions.roadSurfaces === "Are mediocre. Not bad, Not excellent" ? 28 : 32;

      if(tireWidth === 25){
        return wheelsets.filter(wheelset => wheelset.frontRimInnerWidth >= 18 && wheelset.frontRimInnerWidth <= 23);
      }
      else if(tireWidth === 28){
        return wheelsets.filter(wheelset => wheelset.frontRimInnerWidth >= 23 && wheelset.frontRimInnerWidth <= 25);
      }
      else if(tireWidth === 32){
        return wheelsets.filter(wheelset => wheelset.frontRimInnerWidth >= 25 && wheelset.frontRimInnerWidth <= 28);
      }
      else{
        return wheelsets;
      }
    },
    // MAIN SORTING 
    sortWheelsets(scoreList, wheelsets, debug = true) {
      const attributes = [
        { key: 'lowPrice', order: 'asc', getValue: (w) => w.price !== -1 ? w.price : Infinity },
        { key: 'rimDepth', order: 'desc', getValue: (w) => Math.min(w.frontRimDepth !== -1 ? w.frontRimDepth : 0, 60) },
        { key: 'weight', order: 'asc', getValue: (w) => w.weight !== -1 ? w.weight : Infinity },
        { key: 'stiffness', order: 'desc', getValue: (w) => w.stiffness !== -1 ? w.stiffness : 0 }
      ];

      // Sort attributes by their importance in descending order
      const sortedAttributes = attributes
        .filter(attr => scoreList[attr.key] !== 0)
        .sort((a, b) => Math.abs(scoreList[b.key]) - Math.abs(scoreList[a.key]));

      // set a variable based on number of sorted attributes, if 1 then 4, if 2 then 3, if 3 then 2
      let divisionIndex;
      if (sortedAttributes.length === 3) {
        divisionIndex = 2;
      } else if (sortedAttributes.length === 2) {
        divisionIndex = 3;
      } else if (sortedAttributes.length === 1) {
        divisionIndex = 4;
      } else {
        divisionIndex = 4;
      }

      if (debug) {
        console.log("------------DEBUG-----------");
        console.log("Sorted Attributes: ");
        sortedAttributes.forEach(attr => {
          console.log(attr.key + ": " + scoreList[attr.key] + " (" + attr.order + ")");
        });
        console.log("divisionIndex: " + divisionIndex);
        console.log("Each attribute will leave " + 100/divisionIndex + "% of the wheelsets");
        console.log("Beginning with " + wheelsets.length + " wheelsets");
      }



      let remainingWheelsets = wheelsets;

      sortedAttributes.forEach(attr => {
        remainingWheelsets = remainingWheelsets.sort((a, b) => {
          const valueA = attr.getValue(a);
          const valueB = attr.getValue(b);
          const order = scoreList[attr.key] > 0 ? attr.order : (attr.order === 'asc' ? 'desc' : 'asc');
          return order === 'asc' ? valueA - valueB : valueB - valueA;
        });

        let filteredWheelsetLength = Math.ceil(remainingWheelsets.length / divisionIndex);
        if(filteredWheelsetLength < 3){
          console.log("filteredWheelsetLength is less than 3, returning remaining wheelsets");
          return remainingWheelsets;
        }
        remainingWheelsets = remainingWheelsets.slice(0, filteredWheelsetLength);

        if (debug) {
          console.log(`Sorting by ${attr.key}, leaving ${remainingWheelsets.length} wheelsets:`);
          remainingWheelsets.forEach((wheelset, index) => {
            console.log(`${index + 1}. ${wheelset.name} (${attr.key}: ${attr.getValue(wheelset)})`);
          });
        }


      });

      return remainingWheelsets;
    },
    filterValue(value) {
      return value === -1 || value === '-1' ? 'N/A' : value;
    },
    formatPrice(price) {
      return price === -1 || price === '-1' ? 'N/A' : `$${price}`;
    },
    confirmBtnClicked(){
      this.mobileQuestionsConfirmed = true;
      this.showCollapsedQuestions = true;
      this.showConfirmedBtn = false;
    }
  },
  async mounted() {
    document.title = "PP Wheelset Finder";

    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = "https://www.pandapodium.cc/wp-content/uploads/2023/05/Panda_Podium_LogoFavicon.png";

    try {
        const response = await wheelsetService.getAllWheelsets()
        this.wheelsets = response.data.data.results;

        // filter out wheelsets with no spokeType (spokeType = "")
        this.wheelsets = this.wheelsets.filter(wheelset => wheelset.spokeType !== "" && wheelset.spokeType !== "Carbon Tri-Spoke");

        // filter mtb wheelsets (frontRimOutterWidth > 34)
        this.wheelsets = this.wheelsets.filter(wheelset => wheelset.frontRimOutterWidth <= 34);

    } catch (error) {
        console.error('Error fetching JSON:', error);
    }

  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    weightCombined: function(){
      this.generateResults();
      // this.fineTunedWheelsets = this.fineTunedWheelsets.filter(wheelset => wheelset.riderWeightLimit <= val);
    },

    stiffnessLightweightPref: function(){
      this.generateResults();
    },

    rimDepthPref: function(){
      this.generateResults();
    },
    
    // watch mobileQuestionsConfirmed, if true and Object.keys(this.selectedOptions).length === 5, call this.generateResults and set this.showResults to true
    mobileQuestionsConfirmed:function(val){
      if(val){
        this.generateResults;
        this.showResults = true;
      }
    }
  },
};
</script>

<style scoped>
  .option-section {
    margin-bottom: 20px;
  }

  .section-row {
    margin-bottom: 10px;
  }

  .section-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section-title {
    display: flex;
    align-items: center;
  }

  .options-container {
    display: flex;
    flex-wrap: wrap;
  }


  .selected {
    background-color: rgb(var(--v-theme-buttonHover)) !important; 
    color: rgb(var(--v-theme-textPrimary)) !important;
  }

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.option-btn {
  margin: 5px;
  white-space: normal;
  word-break: break-word;
  text-align: center;
  width: auto;
  background: rgb(var(--v-theme-button));
  color: rgb(var(--v-theme-textPrimary));
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.2);border-radius: 5px; padding: 3px 9px;
}

.option-btn:hover {
  background: rgb(var(--v-theme-buttonHover));
}

.title-container-mobile{
  padding: 0px;
}

:deep(.v-slider-thumb__label) {
    background-color: #EA83E9 !important;
}

@media (max-width: 768px) {
  .weFoundxWheelsText{
    /* margin-top:5px; */
    /* margin-bottom: 15px; */
  }

  .fineTuneUI{
    margin-bottom: 10px !important;
  }
}

.scrollable-container {
  overflow-y: scroll; /* Ensure scrolling is enabled */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.scrollable-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
</style>